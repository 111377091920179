import { MailService } from '@/api/services/mail.service'
import { useToast } from '@/components/shared/toast/useToast'
import { DefaultButton } from '@/components/ui/button/DefaultButton'
import { PrimaryButton } from '@/components/ui/button/PrimaryButton'
import { FormControls } from '@/components/ui/form/FormControls'
import { useSettingsSelector } from '@/hooks/settings/use-settings-selector'
import { translate } from '@/i18n'
import { useUserStore } from '@/stores/userStore'
import { InputField } from '@/ui/components/Field/Input'
import { SelectField } from '@/ui/components/Field/Select'
import { FormLabel } from '@/ui/components/Form/FormLabel'
import Grid from '@/ui/components/Grid'
import { Field, Formik } from 'formik'
import React from 'react'
import { useIntl } from 'react-intl'
import { useMutation } from 'react-query'
import styled from 'styled-components'
import { useMaintenanceDialogStore } from './MaintenanceModal'
import { useEmailFromSettings } from '@/hooks/settings/useEmailFromSettings'

export const maintenanceTypes = [
	{ value: '1', label: 'Уборка', alias: 'cleaning' },
	{ value: '2', label: 'Техническое облуживание', alias: 'maintenance' },
]

const mergeMessage = (data: {
	seat?: string
	floor?: number | null
	created?: string
	reason?: string
	message?: string
}) =>
	[
		`Номер места: ${data.seat || '-'}`,
		`Этаж: ${data.floor || '-'}`,
		`Запросил: ${data.created || '-'}`,
		`Причина: ${data.reason || '-'}`,
		`Комментарий: ${data.message || '-'}`,
	].join(';<br />')

const MaintenanceForm: React.FC = () => {
	const [onClose, employee, seat, floor] = useMaintenanceDialogStore(
		(state) => [state.onClose, state.employee, state.seat, state.floor],
	)
	const user = useUserStore((state) => state.user)
	const intl = useIntl()
	const maintenanceAll = useSettingsSelector(
		(settings) => settings.maintenances,
		[],
	)

	const { enqueueToast } = useToast()

	interface mutateDataType {
		seat: string
		maintenanceId: string
		more?: string
		email: string
		employee: string
	}

	const { mutate } = useMutation(
		'maintenance-user',
		(data: mutateDataType) => {
			const maintenanceName =
				maintenanceAll.find(({ id }) => id === data.maintenanceId)?.name ||
				'Обслуживание'

			return MailService.createMaintenance({
				subject: `Заявка на обслуживание: ${maintenanceName}`,
				message: mergeMessage({
					seat: data.seat,
					floor,
					created: user?.display,
					reason: maintenanceName,
					message: data.more,
				}),
				recipient: data.email,
			})
		},
		{
			onSuccess(data) {
				onClose()
				enqueueToast('Отправлено', { variant: 'success' })
			},
			onError(data: any) {
				enqueueToast('Не удалось отправить', { variant: 'error' })
			},
		},
	)

	const { getEmail } = useEmailFromSettings()

	interface FormFields {
		employee: string
		seat: string
		maintenanceId: string
		more?: string
	}

	const handleFormSubmit = (
		{ employee, maintenanceId, seat, more }: FormFields,
		{ setSubmitting },
	) => {
		setSubmitting(true)
		const email = getEmail({ maintenanceId, floor })

		if (email) {
			mutate({
				seat,
				employee,
				more,
				maintenanceId,
				email,
			})
		} else {
			enqueueToast('Email не был определен', { variant: 'error' })
		}

		setSubmitting(false)
	}

	return (
		<Formik
			onSubmit={handleFormSubmit}
			enableReinitialize
			initialValues={{
				seat: seat || '',
				employee: employee || '',
				maintenanceId: maintenanceAll[0]?.id,
				more: '',
			}}
		>
			{({ handleSubmit, values, isSubmitting }) => (
				<form onSubmit={handleSubmit}>
					<FormContainer>
						<Grid container>
							<Grid item xs={12} md={12}>
								<FormLabel>{translate('place')}</FormLabel>
								<Field
									name="seat"
									$fullWidth
									required={true}
									placeholder={intl.formatMessage({ id: 'place' })}
									component={InputField}
									disabled={true}
									style={{ opacity: 0.5 }}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<FormLabel>{translate('employee')}</FormLabel>
								<Field
									name="employee"
									$fullWidth
									required={true}
									placeholder={intl.formatMessage({ id: 'employee' })}
									component={InputField}
									disabled={true}
									style={{ opacity: 0.5 }}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<FormLabel>{translate('service-type')}</FormLabel>
								<Field
									name="maintenanceId"
									$fullWidth
									required={true}
									component={SelectField}
									options={
										maintenanceAll.map(({ id, name }) => ({
											label: name,
											value: id,
										})) || []
									}
								/>
							</Grid>

							<Grid item xs={12} md={12}>
								<FormLabel>{translate('more-info')}</FormLabel>
								<Field
									name="more"
									$fullWidth
									placeholder={intl.formatMessage({ id: 'describe' })}
									component={InputField}
									textarea
									rows={10}
								/>
							</Grid>
						</Grid>
					</FormContainer>

					<ControlsWrapper>
						<FormControls>
							<PrimaryButton $fullWidth type="submit" disabled={isSubmitting}>
								{translate('send')}
							</PrimaryButton>
							<DefaultButton $fullWidth type="button" onClick={onClose}>
								{translate('cancel')}
							</DefaultButton>
						</FormControls>
					</ControlsWrapper>
				</form>
			)}
		</Formik>
	)
}

const FormContainer = styled.div`
	padding: 1rem 0;
	max-height: 100%;
`

export default MaintenanceForm

const ControlsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`
