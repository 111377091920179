import { ISettings } from '@/hooks/settings/settings.interface'
import { WindowCustomType } from '@/shared/types/general'

export class Config {
	apiUrl = 'https://demoadmin.officescheme.ru/'
	withCredentials = false
	projectId = 0
	workspaceId = 0
	settings = {} as ISettings

	constructor() {
		this.settings = this._getSettings()
		this.apiUrl = this.settings.api.url
		this.withCredentials = this.settings.api.withCredentials
		this.projectId = Number(this.settings.projectId)
		this.workspaceId = Number(this.settings.workspaceId)
	}

	private _getSettings() {
		let settings = (window as WindowCustomType).SETTINGS

		settings = {
			...settings,
			departments: Object.keys(settings['colors']) || [],
		}

		return settings
	}
}

const $config = new Config()

export default $config
