import { useSettingsSelector } from './use-settings-selector'

interface GetEmailOptions {
	maintenanceId?: string
	floor?: number | null
}

export const useEmailFromSettings = () => {
	const emailList = useSettingsSelector((settings) => settings.mailing, [])

	const getEmail = ({ maintenanceId, floor }: GetEmailOptions) => {
		let email: string | undefined

		if (maintenanceId && floor) {
			email = emailList.find(
				(props) =>
					props.floors?.includes(floor) &&
					props.maintenanceIds?.includes(maintenanceId),
			)?.email

			if (!email) {
				email = emailList.find(
					(props) =>
						props.maintenanceIds?.includes(maintenanceId) && !props.floors,
				)?.email
			}

			if (!email) {
				email = emailList.find(
					(props) => props.floors?.includes(floor) && !props.maintenanceIds,
				)?.email
			}
		}

		if (!floor && maintenanceId) {
			email = emailList.find(
				(props) =>
					props.maintenanceIds?.includes(maintenanceId) && !props.floors,
			)?.email
		}

		if (floor && !maintenanceId) {
			email = emailList.find(
				(props) => props.floors?.includes(floor) && !props.maintenanceIds,
			)?.email
		}

		if (!floor && !maintenanceId) {
			email = emailList.find(
				(props) => !props.floors && !props.maintenanceIds,
			)?.email
		}

		if (!email) {
      email = emailList.find(
				(props) => !props.floors && !props.maintenanceIds,
			)?.email

      if (!email) {
        return null
      }
		}

		return email
	}

	return { getEmail }
}
