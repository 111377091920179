import { StatusResponseEnum } from '@/api';
import { BookingItem } from '@/api/bookings';
import { MomentType, useBookings } from '@/api/hooks/useBookings';
import { useMetadata } from '@/api/hooks/useMetadata';
import { BookingService } from '@/api/services/booking.service';
import { confirmDialog } from '@/components/ConfirmDialog';
import { TableCell } from '@/components/Employee/Employees';
import { Grid } from '@/components/Grid';
import Toolbar from '@/components/Toolbar/Toolbar';
import { DeleteIcon, EditIcon } from '@/components/icons';
import PointIcon from "@/components/icons/PointIcon";
import { bookingDialog } from '@/components/shared/booking/form/BookingModal';
import { useToast } from '@/components/shared/toast/useToast';
import { useProject } from '@/hooks/useProject';
import useResponsive from '@/hooks/useResponsive';
import useSorting from '@/hooks/useSorting';
import { translate } from '@/i18n';
import { useGlobalStore } from "@/stores/globalStore";
import { useMapStore } from "@/stores/mapStore";
import { RolesEnum, useUserStore } from '@/stores/userStore';
import { Input } from '@/ui/components/Field/Input';
import { SelectInput } from '@/ui/components/Field/Select';
import Pagination from '@/ui/components/Pagination/Pagination';
import { extractGaps } from '@/utils/helpers/dates.helpers';
import { addMinutes, format, parseISO, startOfWeek } from 'date-fns';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import styled, { css } from 'styled-components';
import MobileBookingItem from './MobileBookingItem';
import Sidebar from './Sidebar';



const Bookings = () => {
    const [currentPage, setCurrentPage] = useState<number>(1)
    const { isDesktop } = useResponsive()
    const [placeType, setPlaceType] = useState<string>('')
    // const [bookingType, setBookingType] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [place, setPlace] = useState<string>('')
    const [moment, setMoment] = useState<MomentType>('all')
    const { sort, direction, handleSort } = useSorting()
    const intl = useIntl()
    const user = useUserStore(state => state.user)

    const { data } = useBookings({
        page: currentPage,
        perPage: 20,
        moment,
        // day,
        my: user?.display,
        name,
        user: name,
        place,
        placeType,
        sort,
        direction
    })
    const { metadata } = useMetadata()

    const handleNameChange = (e) => setName(e?.target?.value)
    const handlePlaceChange = (e) => setPlace(e?.target?.value)
    const handlePlaceTypeChange = (e) => setPlaceType(e?.target?.value)
    // const handleBookingTypeChange = (e) => setBookingType(e?.target?.value)

    const debouncedNameResponse = useMemo(() => {
        return debounce(handleNameChange, 500)
    }, [])

    useEffect(() => {
        return () => debouncedNameResponse.cancel()
    }, [])

    const debouncedPlaceResponse = useMemo(() => {
        return debounce(handlePlaceChange, 500)
    }, [])

    useEffect(() => {
        return () => debouncedPlaceResponse.cancel()
    }, [])


    const role = useUserStore(state => state.role)
    const isAdmin = role === RolesEnum.Admin
    const template = isAdmin ? '2fr 2fr 1fr 3fr 90px' : '2fr 1fr 3fr 90px'

    const nodes: any = useMemo(() => metadata?.nodes?.reduce((acc, node) => {
        const plugins: any[] = Object.values(node.plugin_data)
        
        const isBookable = plugins.find(d => d.bookable)

        if (!isBookable) return acc

        return acc.concat(node)
    }, [] as any), [metadata?.nodes])

    return (
        <Sidebar minWidth={isDesktop ? 1024 : 1}>
            <Sidebar.Header showAdd onAdd={() => bookingDialog({})} title="bookings" />

            {isAdmin && (
                <Toolbar>
                    <Toolbar.Item xs={6} md={4}>
                        <Toolbar.Label>
                            {translate('search-bookings-by-user')}
                        </Toolbar.Label>
                        <Input $fullWidth placeholder={intl.formatMessage({ id: 'search' })} onChange={debouncedNameResponse} />
                    </Toolbar.Item>
                    <Toolbar.Item xs={6} md={4}>
                        <Toolbar.Label>
                            {translate('search-bookings-by-object')}
                        </Toolbar.Label>
                        <Input $fullWidth placeholder={intl.formatMessage({ id: 'search' })} onChange={debouncedPlaceResponse} />
                    </Toolbar.Item>
                    <Toolbar.Item xs={6} md={4}>
                        <Toolbar.Label>{translate('object-type')}</Toolbar.Label>
                        <SelectInput $fullWidth placeholder="Выберите тип места" value={placeType} onChange={handlePlaceTypeChange}>
                            <option value="">{translate('all')}</option>
                            {nodes?.map(node => <option key={node.uid} value={node.uid}>{node.name}</option>)}
                        </SelectInput>
                    </Toolbar.Item>
                    {/* <Toolbar.Item xs={6} md={3}>
                        <Toolbar.Label>Тип брони</Toolbar.Label>
                        <SelectInput $fullWidth placeholder="Выберите тип места"  value={bookingType} onChange={handleBookingTypeChange}>
                            <option value="0">Все</option>
                            <option value="1">Обычная</option>
                            <option value="2">Еженедельная</option>
                            <option value="3">Постоянная</option>
                        </SelectInput>
                    </Toolbar.Item> */}
                </Toolbar>
            )}

            <Sections>
                <SectionItem moment={moment} name="all" setActive={setMoment}>{translate('all-bookings')}</SectionItem>
                {isAdmin && <SectionItem moment={moment} name="my" setActive={setMoment}>{translate('my-bookings')}</SectionItem>}
                <SectionItem moment={moment} name="past" setActive={setMoment}>{translate('past-bookings')}</SectionItem>
                <SectionItem moment={moment} name="current" setActive={setMoment}>{translate('current-bookings')}</SectionItem>
                <SectionItem moment={moment} name="future" setActive={setMoment}>{translate('future-bookings')}</SectionItem>
                <SectionItem moment={moment} name="constant" setActive={setMoment}>{translate('constant-bookings')}</SectionItem>
                <SectionItem moment={moment} name="recurrent" setActive={setMoment}>{translate('weekly-bookings')}</SectionItem>
                {/* <SectionItem moment={moment} name="recurrent" setActive={setMoment}>Постоянные</SectionItem> */}
            </Sections>

            <Grid>
                {isDesktop && (
                    <Grid.RowHeader $cols={template}>
                        {isAdmin && <TableCell
                            onClick={handleSort.bind(null, 'user')}
                            $active={sort === 'user'}
                            $direction={direction}
                        >
                            {translate('full-name')}
                        </TableCell>}
                        <TableCell
                            onClick={handleSort.bind(null, 'place')}
                            $active={sort === 'place'}
                            $direction={direction}
                        >
                            {translate('location')}
                        </TableCell>
                        <Grid.Item>
                            {translate('type')}
                        </Grid.Item>
                        <TableCell
                            onClick={handleSort.bind(null, 'starts')}
                            $active={sort === 'starts'}
                            $direction={direction}
                        >
                            {translate('date')}
                        </TableCell>
                        <Grid.Item />
                    </Grid.RowHeader>
                )}

                {!data ? (
                    <div>Не найдено</div>
                ) : data?.items.slice(0, 20).map(item => <ElementItem key={item.id} isAdmin={isAdmin} item={item} />)}
            </Grid>

            <Pagination inverse total={data?.total || 0} currentPage={currentPage} itemsPerPage={20} handlePageChange={setCurrentPage} />

        </Sidebar>
    )
}

export default Bookings

const SectionItem = ({ moment, name, setActive, children }) => {

    const handleOnchange = () => setActive(name)

    return (
        <SecItem
            $active={moment === name}
            onClick={handleOnchange}
        >
            {children}
        </SecItem>
    )
}

const ElementItem: React.FC<{ item: BookingItem, isAdmin: boolean }> = ({ item, isAdmin }) => {
    const bookingType = item.rec != '0' ? 'recurrent' : item.end ? 'common' : 'constant'
    const queryClient = useQueryClient()
    const { workspaceId, projectId } = useProject()
    const { enqueueToast } = useToast()
    const template = isAdmin ? '2fr 2fr 1fr 3fr 90px' : '2fr 1fr 3fr 90px'
    const { isDesktop } = useResponsive()

    const setSeat = useGlobalStore(state => state.setSeat)
    const setSeatEmployee = useGlobalStore(state => state.setSeatEmployee)
    const setActiveLayer = useGlobalStore(state => state.setActiveLayer)
    const setZoomSeat = useMapStore(state => state.setZoomSeat)

    const intl = useIntl()

    // const activeLayer = useGlobalStore(state => state.activeLayer)
    // const selection = useGlobalStore(state => state.selection)

    const removeBooking = async () => {
        try {
            const response = await BookingService.removeBooking({ workspaceId, projectId, bookingId: Number(item.id) })

            if (response && response.data.status === StatusResponseEnum.Success) {
                queryClient.refetchQueries(['bookings'])

                // remove cache data for map
                queryClient.refetchQueries('bookings_for_layer')

                enqueueToast({ title: intl.formatMessage({ id: 'success' }), message: intl.formatMessage({ id: 'booking-deleted' }) }, { variant: 'success' })
            }
        } catch (e) {
            enqueueToast({ title: intl.formatMessage({ id: 'error' }), message: intl.formatMessage({ id: 'failed-to-delete-booking' }) }, { variant: 'error' })
        }
    }

    const handleEdit = () => bookingDialog({ bookingId: Number(item.id) })
    const handleDelete = (e) => {
        return confirmDialog({
            title: intl.formatMessage({ id: 'delete-booking' }),
            message: intl.formatMessage({ id: 'delete-booking-confirm' }),
            onSubmit: removeBooking
        })
    }

    const handleSeatView = (e) => {
        e.preventDefault()

        setActiveLayer(Number(item.parent_layer_id))
        setSeatEmployee(null)
        setSeat(Number(item.bookable_id))
        setZoomSeat(Number(item.bookable_id))
    }

    if (!isDesktop) return (
        <MobileBookingItem
            fio={isAdmin ? item.user : ''}
            name={item.name}
            type={translate(bookingType)}
            date={<BookingDate type={bookingType} gap={item.gap} date={{ start: item.start, end: item.end }} />}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleSeatView={handleSeatView}
        />
    )

    return (
        <Grid.Line $cols={template}>
            {isAdmin && <BookingCol>{item.user}</BookingCol>}
            <BookingCol>{item.name}</BookingCol>
            <BookingCol>{translate(bookingType)}</BookingCol>
            <BookingCol>
                <ErrorBoundary
                    fallback={<div>{translate('date-display-error')}</div>}
                >
                    <BookingDate type={bookingType} gap={item.gap} date={{ start: item.start, end: item.end }} />
                </ErrorBoundary>
            </BookingCol>
            <BookingCol style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-around" }}>
                <Grid.Item>
                    <a href="#"onClick={handleSeatView}>
                        <PointIcon />
                    </a>
                </Grid.Item>
                <ActionButton onClick={handleEdit}>
                    <EditIcon color="#0150B5" />
                </ActionButton>
                <ActionButton
                    onClick={handleDelete}
                >
                    <DeleteIcon color="#0150B5" />
                </ActionButton>
            </BookingCol>
        </Grid.Line>
    )
}

const BookingCol = styled(Grid.Item)`
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
`

const ActionButton = styled.div`
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const bookingTypeTranslate = {
    'common': 'Обычная',
    'recurrent': 'Еженедельная',
    'constant': 'Постоянная',
}

type BookingDateProps = {
    type: "common" | "recurrent" | "constant"
    gap: string
    date: {
        start: string
        end: string
    }
    inverse?: boolean
}

const d = new Date()
const offset = d.getTimezoneOffset()

export const BookingDate: React.FC<BookingDateProps> = ({ type, date, gap, inverse = false }) => {

    const [open, setOpen] = useState(false)

    if (type === 'recurrent') {
        const gapToSlots = Array.isArray(gap) ? gap.join(',') : gap
        const schedule =  extractSchedule(gapToSlots.split(','))

        return (
            <div>
                <ScheduleWrapper $expandable={true} $expanded={open} $inverse={inverse} onClick={() => setOpen(!open)}>
                    {`${format(parseISO(date.start + '.000Z'), 'dd.MM.yyyy HH:mm')} - ${format(parseISO(date.end + '.000Z'), 'dd.MM.yyyy HH:mm')}`}
                </ScheduleWrapper>
                <ScheduleContent $expanded={open}>
                    {schedule.map(slot => (
                        <p key={slot.start.toISOString()}>
                            <b>{weekdays[new Date(slot.start).getDay()]}</b> {format(addMinutes(slot.start, -offset), 'HH:mm')} - {format(addMinutes(slot.end, -offset), 'HH:mm')}
                        </p>
                    ))}
                </ScheduleContent>
            </div>
        )
    }

    return (
        <div>
            <ScheduleWrapper $inverse={inverse}>
                {format(parseISO(date.start + '.000Z'), 'dd.MM.yyyy HH:mm')}{type !== 'constant' ? ` - ${format(parseISO(date.end + '.000Z'), 'dd.MM.yyyy HH:mm')}` : ''}
            </ScheduleWrapper>
        </div>
    )
}

const weekdays = {
    1: 'Пн.',
    2: 'Вт.',
    3: 'Ср.',
    4: 'Чт.',
    5: 'Пт.',
    6: 'Сб.',
    0: 'Вс.',
}

const interval = 30 * 60 * 1000

export const extractSchedule = (gap: string[]) => {
    const weekStart = startOfWeek(new Date(), { weekStartsOn: 1 })
    const gaps = extractGaps(gap)

    return gaps.map(slot => ({
        start: new Date(weekStart.getTime() + 30 * 60 * 1000 * slot[0]),
        end: new Date(weekStart.getTime() + interval * Number(slot[1]) + interval)
    }))
}

const ScheduleWrapper = styled.div<{ $expandable?: boolean, $expanded?: boolean, $inverse?: boolean }>`
    padding-right: 20px;
    position: relative;

   ${({ $expandable, $expanded, $inverse }) => $expandable && css`
        cursor: pointer;

        &::after {
            content: ' ';
            position: absolute;
            background-image: ${$inverse ? `url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='1' width='1.41421' height='8.48527' transform='rotate(-45 0 1)' fill='%23000000'/%3E%3Crect x='11' width='1.41421' height='8.48527' transform='rotate(45 11 0)' fill='%23000000'/%3E%3C/svg%3E%0A")` : `url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='1' width='1.41421' height='8.48527' transform='rotate(-45 0 1)' fill='%23FDFDFD'/%3E%3Crect x='11' width='1.41421' height='8.48527' transform='rotate(45 11 0)' fill='%23FDFDFD'/%3E%3C/svg%3E%0A")`};
            width: 12px;
            height: 7px;
            background-size: cover;
            top: 12px;
            right: 5px;
            transition: all 0.5s;
            transform: ${$expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
        }
    `}
`
const ScheduleContent = styled.div<{ $expanded?: boolean }>`
    height: auto;
    max-height: 0px;
    margin-top: 8px;
    transition: all 0.5s;
    overflow: hidden;

    ${({ $expanded }) => $expanded && css`
        max-height: 200px;
    `}

    p {
        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }
`

const Sections = styled.div`
    display: flex;
    margin-bottom: 8px;
    flex-shrink: 0;
    flex-wrap: wrap;
`

const SecItem = styled.div<{ $active?: boolean }>`
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #0150b5;
    cursor: pointer;

    ${({ $active }) => $active ? css`
        border-bottom: 2px solid #0150b5;
        opacity: 1
    ` : css`
        opacity: 0.5;
        border-bottom: 2px solid transparent;
    `}

    &:hover {
        border-bottom: 2px solid #0150b5;
        opacity: 1
    }

    &:not(:last-child) {
        margin-right: 24px;
    }
`